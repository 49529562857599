var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('backButton'),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"xs":"12"}},[_c('v-form',{ref:"form",attrs:{"id":"editPageItemForm","lazy-validation":""}},[(_vm.page && _vm.selectedItem)?[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"xs":"12"}},[(
                  this.$route.params.itemId == 0 ||
                  this.$route.params.itemId == undefined
                )?_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("Add new item to"))+" "+_vm._s(_vm.page.Name[_vm.currentLanguage])+" ")]):(this.$route.params.isCopy)?_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("Copy item in"))+" "+_vm._s(_vm.page.Name[_vm.currentLanguage])+" ")]):(this.$route.params.isDetail)?_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("Detail item in"))+" "+_vm._s(_vm.page.Name[_vm.currentLanguage])+" ")]):_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("Edit item in"))+" "+_vm._s(_vm.page.Name[_vm.currentLanguage])+" ")]),(
                  _vm.objectValidationErrors.length > 0 && _vm.userIsAdministrator
                )?_c('errorHandeling',{attrs:{"snackbarText":_vm.objectValidationErrors,"buttons":[
                  {
                    isText: true,
                    functionName: 'clearError',
                    text: 'Close',
                  },
                ],"snackbarColor":"warning","snackbarTimout":"-1","snackbarIcon":"mdi-alert","isSpecial":true}}):_vm._e()],1)],1),_c('v-row',{attrs:{"dense":""}},[_vm._l((_vm.selectedItem.Data),function(item,index){return [_c('pageElement',{key:index,attrs:{"item":item,"websiteId":_vm.page.WebsiteId.$oid,"pageId":_vm.page._id.$oid,"objectName":index,"colsWidth":item.colWidth ? item.colWidth : 12},on:{"update:saving":function($event){_vm.saving = $event}}})]})],2)]:(!_vm.$store.getters.error)?[_c('preloader')]:_vm._e(),(!this.$route.params.isDetail)?_c('cancelConfirmButtons',{ref:"cancelConfirm",attrs:{"confirmFunction":_vm.savePageContent,"loading":_vm.saving,"confirmLabel":_vm.$t('Save')},on:{"goBack":_vm.goBack}}):_vm._e(),(_vm.success.length > 0)?_c('errorHandeling',{attrs:{"snackbarText":_vm.success,"buttons":[
            {
              isText: true,
              functionName: 'clearError',
              text: 'Close',
            },
          ],"snackbarColor":"success","snackbarTimout":"-1","snackbarIcon":"mdi-check"},on:{"clearError":function($event){_vm.success = ''}}}):_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }