<template>
  <div>
    <backButton />
    <v-row no-gutters>
      <v-col xs="12">
        <v-form ref="form" id="editPageItemForm" lazy-validation>
          <template v-if="page && selectedItem">
            <v-row no-gutters>
              <v-col xs="12">
                <h2
                  v-if="
                    this.$route.params.itemId == 0 ||
                    this.$route.params.itemId == undefined
                  "
                  class="mb-3"
                >
                  {{ $t("Add new item to") }} {{ page.Name[currentLanguage] }}
                </h2>
                <h2 v-else-if="this.$route.params.isCopy" class="mb-3">
                  {{ $t("Copy item in") }} {{ page.Name[currentLanguage] }}
                </h2>
                <h2 v-else-if="this.$route.params.isDetail" class="mb-3">
                  {{ $t("Detail item in") }} {{ page.Name[currentLanguage] }}
                </h2>
                <h2 v-else class="mb-3">
                  {{ $t("Edit item in") }} {{ page.Name[currentLanguage] }}
                </h2>
                <errorHandeling
                  v-if="
                    objectValidationErrors.length > 0 && userIsAdministrator
                  "
                  :snackbarText="objectValidationErrors"
                  :buttons="[
                    {
                      isText: true,
                      functionName: 'clearError',
                      text: 'Close',
                    },
                  ]"
                  snackbarColor="warning"
                  snackbarTimout="-1"
                  snackbarIcon="mdi-alert"
                  :isSpecial="true"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <template v-for="(item, index) in selectedItem.Data">
                <pageElement
                  :key="index"
                  :item="item"
                  :websiteId="page.WebsiteId.$oid"
                  :pageId="page._id.$oid"
                  :objectName="index"
                  :colsWidth="item.colWidth ? item.colWidth : 12"
                  @update:saving="saving = $event"
                />
              </template>
            </v-row>
          </template>
          <template v-else-if="!$store.getters.error">
            <preloader></preloader>
          </template>
          <cancelConfirmButtons
            v-if="!this.$route.params.isDetail"
            ref="cancelConfirm"
            @goBack="goBack"
            :confirmFunction="savePageContent"
            :loading="saving"
            :confirmLabel="$t('Save')"
          />

          <errorHandeling
            v-if="success.length > 0"
            :snackbarText="success"
            :buttons="[
              {
                isText: true,
                functionName: 'clearError',
                text: 'Close',
              },
            ]"
            snackbarColor="success"
            snackbarTimout="-1"
            snackbarIcon="mdi-check"
            @clearError="success = ''"
          />
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: "",
      success: "",
      selectedItem: null,
      page: null,
      saving: false,
      objectValidationErrors: "",
      userIsAdministrator: this.$auth.userIsAdministrator(),
    };
  },
  created() {
    this.getPage();
  },
  watch: {
    "$route.params.id": function () {
      this.getPage();
    },
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getPage() {
      this.$request.get("/page/" + this.$route.params.id, null, (res) => {
        if (res.success) {
          if (res.data.Content == null) {
            res.data.Content = [];
            res.data.Content[0] = {};
          }

          if (res.data.ContentDefinition == null) {
            res.data.ContentDefinition = {};
          }

          this.page = res.data;
          this.$store.dispatch("setSelectedWebsiteById", this.page.WebsiteId);
          if (!this.$route.params.itemId) {
            this.selectedItem = {
              PageId: this.page._id,
              WebsiteId: this.page.WebsiteId,
              Data: this.page.ContentDefinition,
            };
          } else {
            this.getContent();
          }
        } else {
          this.$store.dispatch("setError", res);
        }
      });
    },
    getContent() {
      this.$request.get(
        "/content/" + this.$route.params.itemId,
        null,
        (res) => {
          if (res.success) {
            this.selectedItem = res.data;
            this.objectValidationErrors =
              this.$helpers.pageItemDefinitionEqualToContentDefinition(
                this.selectedItem.Data,
                this.page.ContentDefinition
              );
            //needed for old data, if field got requird prop add new prop
            for (const key in this.selectedItem.Data) {
              if (
                this.selectedItem.Data[key].hasOwnProperty.call(
                  this.selectedItem.Data[key],
                  "Required"
                )
              ) {
                if (
                  this.selectedItem.Data[key].Required &&
                  (this.selectedItem.Data[key].Type == "text" ||
                    this.selectedItem.Data[key].Type == "richtext")
                ) {
                  this.selectedItem.Data[key]["FieldValidation"] = true;
                  this.selectedItem.Data[key]["FieldValidationFuntion"] =
                    "notEmptyStringRule";
                } else if (
                  this.selectedItem.Data[key].Required &&
                  this.selectedItem.Data[key].Type == "number"
                ) {
                  this.selectedItem.Data[key]["FieldValidation"] = true;
                  this.selectedItem.Data[key]["FieldValidationFuntion"] =
                    "notEmptyNumberRule";
                }
              }
            }
          } else {
            this.$store.dispatch("setError", res);
          }
        }
      );
    },
    savePageContent() {
      this.$store.dispatch("resetError");
      let formIsValid = this.$refs.form.validate();
      if (formIsValid) {
        this.saving = true;
        this.selectedItem.DisplayLabel = this.$helpers.getDisplayLabel(
          this.selectedItem,
          this.page.DisplayLabelCustomFormat
        );
        const langs = this.$config.contentLanguages;
        if (!this.selectedItem.DisplayLabels) {
          this.selectedItem.DisplayLabels = {};
        }
        langs.forEach((lang) => {
          this.selectedItem.DisplayLabels[lang] = this.$helpers.getDisplayLabel(
            this.selectedItem,
            this.page.DisplayLabelCustomFormat,
            lang
          );
        });
        if (this.$route.params.isCopy) {
          this.$delete(this.selectedItem, "_id");
        }
        this.$request.put("/content", this.selectedItem, (res) => {
          this.saving = false;
          if (res.success) {
            if (
              this.page.SynchronizationPutUrl &&
              this.page.SynchronizationPutUrl.length > 0
            ) {
              this.$request.put(
                this.page.SynchronizationPutUrl,
                this.selectedItem,
                (res) => {
                  if (!res.success) {
                    this.$store.dispatch("setError", res);
                  }
                }
              );
            }
            this.$router.go(-1);
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      } else {
        this.$store.dispatch(
          "setError",
          this.$i18n.translate("Please make sure all text fields have a value")
        );
      }
    },
  },
};
</script>

<style>
</style>
